import React from "react"
import styled from "styled-components";

const ListContainer = styled.ol`
  margin: 2rem 2rem;

  & > li {
    margin: 1.1rem;
    padding-left: 0.5rem;
  }
`;

type Props = {
  children: React.ReactNode;
}

const List = ({ children }: Props) => (
  <div>
    <ListContainer>
      { children }
    </ListContainer>
  </div>
)

export default List
